import React from 'react';
import { Users, Lightbulb, Scale, Handshake } from 'lucide-react';

const About = ({ isDarkMode }) => {
  // Define modern colors
  const colors = {
    background: isDarkMode ? 'bg-[#1e293b]' : 'bg-[#f8fafc]',
    textPrimary: isDarkMode ? 'text-[#e2e8f0]' : 'text-[#2d3748]',
    textSecondary: isDarkMode ? 'text-[#94a3b8]' : 'text-[#718096]',
    accentBlue: isDarkMode ? 'text-[#60a5fa]' : 'text-[#3b82f6]',
    accentGreen: isDarkMode ? 'text-[#4ade80]' : 'text-[#22c55e]',
    accentYellow: isDarkMode ? 'text-[#facc15]' : 'text-[#eab308]',
    accentRed: isDarkMode ? 'text-[#f87171]' : 'text-[#dc2626]',
    cardBackground: isDarkMode ? 'bg-[#2c3e50]' : 'bg-[#ffffff]',
  };

  return (
    <div className={`min-h-screen ${colors.background} ${colors.textPrimary} flex flex-col items-center`}>
      <main className="flex-grow px-6 py-10 max-w-4xl w-full space-y-12">

{/* Mission Section */}
<section className="mb-12 text-center">
<h1 className="text-3xl font-extrabold mb-4">Our Mission</h1>
<p className="text-base max-w-lg mx-auto">
            At SmileCare Analytics, we empower pediatric dental practices to make informed, data-driven decisions. Our goal is to simplify complex data into actionable insights that optimize patient care.

          </p>
        </section>





{/* Who We Are */}
<section className="mb-10 text-left">
  <h2 className="text-2xl font-semibold mb-4">Who We Are</h2>
  <p className="text-base">
    We are a dedicated team of data enthusiasts and healthcare professionals passionate about improving pediatric dental practices. Our diverse backgrounds in data science, engineering, and healthcare drive our commitment to patient care.
  </p>
</section>

{/* What We Do */}
<section className="mb-10 text-left">
  <h2 className="text-2xl font-semibold mb-4">What We Do</h2>
  <p className="text-base">
    We provide advanced analytics and AI solutions tailored for dental practices. From key metric tracking to operational streamlining, our tools give clarity to your practice’s performance and patient care.
  </p>
</section>


        {/* Our Values */}
        <section className="mb-16">
          <h2 className="text-2xl font-semibold mb-6 text-center">Our Values</h2>
          <div className="grid gap-6 sm:grid-cols-2">
            <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md text-center`}>
              <Users className={`${colors.accentBlue} h-10 w-10 mb-3 mx-auto`} />
              <h3 className="text-lg font-semibold mb-1">Client-Centered</h3>
              <p className={`text-sm ${colors.textSecondary}`}>Our clients’ needs are at the heart of everything we do.</p>
            </div>
            <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md text-center`}>
              <Lightbulb className={`${colors.accentGreen} h-10 w-10 mb-3 mx-auto`} />
              <h3 className="text-lg font-semibold mb-1">Innovation</h3>
              <p className={`text-sm ${colors.textSecondary}`}>We embrace technology to create meaningful change in healthcare.</p>
            </div>
            <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md text-center`}>
              <Scale className={`${colors.accentYellow} h-10 w-10 mb-3 mx-auto`} />
              <h3 className="text-lg font-semibold mb-1">Integrity</h3>
              <p className={`text-sm ${colors.textSecondary}`}>Our work is rooted in honesty, transparency, and ethics.</p>
            </div>
            <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md text-center`}>
              <Handshake className={`${colors.accentRed} h-10 w-10 mb-3 mx-auto`} />
              <h3 className="text-lg font-semibold mb-1">Collaboration</h3>
              <p className={`text-sm ${colors.textSecondary}`}>We work alongside our clients to create tailored solutions.</p>
            </div>
          </div>
        </section>

{/* Our Journey */}
<section className="mb-10 text-left">
  <h2 className="text-2xl font-semibold mb-4">Our Journey</h2>
  <p className="text-base">
    Founded with a vision to make data accessible and actionable, SmileCare Analytics is now a trusted partner for pediatric dental practices nationwide. We’re proud to help practices thrive through the power of data.
  </p>
</section>

{/* Contact Us */}
<section className="text-left mt-12">
  <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
  <p className="text-base">
    Want to learn more about how SmileCare Analytics can support your practice? Reach out to us at <strong>contact@smilecareanalytics.com</strong>.
  </p>
</section>

      </main>

      {/* Footer Section */}
      {/* <footer className={`py-6 w-full text-center ${colors.background}`}>
        <div className={`text-xs ${colors.textSecondary}`}>
          © {new Date().getFullYear()} SmileCare Analytics. All rights reserved.
        </div>
      </footer> */}
    </div>
  );
};

export default About;
