import React from 'react';
import { Mail, Phone } from 'lucide-react';

const Support = ({ isDarkMode }) => {
  // Define modern colors
  const colors = {
    background: isDarkMode ? 'bg-[#1e293b]' : 'bg-[#f8fafc]', // Dark slate or light gray-blue
    textPrimary: isDarkMode ? 'text-[#e2e8f0]' : 'text-[#2d3748]', // Light blue-gray or dark slate
    textSecondary: isDarkMode ? 'text-[#94a3b8]' : 'text-[#718096]', // Muted blue-gray
    accentGreen: isDarkMode ? 'text-[#4ade80]' : 'text-[#22c55e]', // Soft green for icons
    accentBlue: isDarkMode ? 'text-[#60a5fa]' : 'text-[#3b82f6]', // Soft blue for icons
    cardBackground: isDarkMode ? 'bg-[#2c3e50]' : 'bg-[#ffffff]', // Dark slate for dark mode, white for light
  };

  return (
    <div className={`min-h-screen ${colors.background} ${colors.textPrimary} flex flex-col items-center`}>
      <main className="flex-grow px-6 py-10 max-w-3xl w-full">

        {/* Welcome Message */}
        <section className="mb-12 text-center">
          <h1 className="text-3xl font-extrabold mb-4">We're Here for You</h1>
          <p className="text-base max-w-lg mx-auto">
            At SmileCare Analytics, we know that reliable support is essential for you and your practice. Our team is ready to assist you, whether you need help with setup, troubleshooting, or just a guiding hand.
          </p>
        </section>

        {/* Support Channels */}
        <section className="mb-10">
          <h2 className="text-xl font-semibold mb-4">Connect with Our Support Team</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className={`${colors.cardBackground} p-5 shadow-md flex flex-col items-center rounded-lg`}>
              <Mail className={`${colors.accentGreen} h-8 w-8 mb-2`} />
              <p className="font-semibold text-base">Email Support</p>
              <p className="text-sm text-center">Reach out anytime at <strong>support@smilecareanalytics.com</strong></p>
              <p className={`text-xs text-center mt-2 ${colors.textSecondary}`}>Typical response time: within 24 hours</p>
            </div>
            <div className={`${colors.cardBackground} p-5 shadow-md flex flex-col items-center rounded-lg`}>
              <Phone className={`${colors.accentBlue} h-8 w-8 mb-2`} />
              <p className="font-semibold text-base">Phone Support</p>
              <p className="text-sm text-center">For urgent issues, call us during support hours.</p>
              <p className={`text-xs text-center mt-2 ${colors.textSecondary}`}>Available for Premium Plan users</p>
            </div>
          </div>
        </section>

        {/* Support Hours */}
        <section className="mb-10 text-center">
          <h2 className="text-xl font-semibold mb-4">Support Hours</h2>
          <p className="text-base">Monday to Friday: 9:00 AM - 5:00 PM EST</p>
          <p className={`text-xs mt-2 ${colors.textSecondary}`}>We’re committed to responding promptly during these hours.</p>
        </section>

        {/* Setup and Configuration */}
        <section className="mb-10">
          <h2 className="text-xl font-semibold mb-4">Seamless Setup and Configuration</h2>
          <p className="text-sm">
            Our team will work closely with your practice to set up data integrations securely and effectively. This includes assistance with API keys and any necessary configuration to connect your systems to SmileCare Analytics.
          </p>
          <p className={`text-xs mt-2 ${colors.textSecondary}`}>Your data is secure with us; credentials are used only for integration purposes.</p>
        </section>

        {/* Limitations of Support */}
        <section className="mb-10">
          <h2 className="text-xl font-semibold mb-4">Limitations of Support</h2>
          <p className="text-sm">
            While we strive to assist with SmileCare Analytics-related issues, please note we aren’t able to provide support for third-party platforms or unrelated IT infrastructure issues.
          </p>
          <p className={`text-xs mt-2 ${colors.textSecondary}`}>This includes platforms like Dentrix, Open Dental, and others.</p>
        </section>

        {/* Contact Us */}
        <section className="mb-10 text-center">
          <h2 className="text-xl font-semibold mb-4">Need Assistance? Contact Us</h2>
          <p className="text-sm max-w-md mx-auto">
            Don’t hesitate to reach out via email or phone during support hours. Our team is here to ensure a smooth experience with SmileCare Analytics.
          </p>
          <p className={`text-xs mt-2 ${colors.textSecondary}`}>We appreciate your trust in us to support your practice.</p>
        </section>
      </main>
    </div>
  );
};

export default Support;
