import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = ({ isDarkMode }) => {
  const navigate = useNavigate();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-slate-900 text-white' : 'bg-white text-gray-900'} flex flex-col`}>
      {/* Header Section */}
      <header className={`py-6 px-4 md:px-8 ${isDarkMode ? 'bg-slate-800' : 'bg-gray-100'} shadow-md`}>
        <div className="max-w-6xl mx-auto flex items-center justify-between">
          <button
            onClick={() => navigate('/')}
            className="text-sm font-medium text-blue-600 hover:text-blue-700 transition-colors"
          >
            ← Home
          </button>
          <h1 className="text-lg font-semibold">
            Privacy Policy
          </h1>
          <span className="text-xs text-gray-500">Last Updated: 2024-11-05</span>
        </div>
      </header>

      {/* Content Section */}
      <main className="flex-grow px-6 py-8 max-w-4xl mx-auto">
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">Introduction</h2>
          <p className="text-base">
            At SmileCare Analytics, we are committed to protecting the privacy and security of our clients and their data. This Privacy Policy outlines how we collect, use, and safeguard information when you use our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">Information We Collect</h2>
          <p className="text-base">
            We collect personal information such as your name, email, and phone number for communication and support. We also gather practice-related details, including the platforms you use, and securely handle API keys and credentials for data integration purposes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">How We Use Your Information</h2>
          <p className="text-base">
            Your information is used to set up and optimize data connections, provide real-time analytics, and communicate important updates or service changes. We strive to improve your experience through seamless data integration and actionable insights.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">How We Protect Your Information</h2>
          <p className="text-base">
            We use industry-standard security measures, such as data encryption and access controls, to safeguard your data. Despite our best efforts, we acknowledge that no method of transmission over the Internet is 100% secure.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">Data Sharing</h2>
          <p className="text-base">
            We do not sell or share your data with third parties, except as required by law or to facilitate data integrations with your explicit permission. Your privacy is a top priority, and we ensure that your information is handled responsibly.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">Data Retention</h2>
          <p className="text-base">
            We retain your information only as long as necessary to provide our services or as legally required. Our retention policies are designed to ensure compliance while respecting your privacy.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">Your Rights</h2>
          <p className="text-base">
            You have the right to access, correct, or request the deletion of your data. We are committed to honoring your requests in compliance with legal and contractual obligations.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
          <p className="text-base">
            If you have any questions or concerns about this Privacy Policy, please contact us at: <strong>[Email Address]</strong>
          </p>
        </section>
      </main>

      {/* Footer Section */}

    </div>
  );
};

export default PrivacyPolicy;
