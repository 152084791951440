import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Sun, Moon, Database, BarChart2, Menu, Cpu, X } from 'lucide-react';
import './index.css';
import CompactDashboard from './CompactDashboard';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Support from './Support';
import Careers from './Careers';
import About from './About';
import Pricing from './About';
import ScrollToTop from './ScrollToTop';


const PediatricDashboardLandingPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  

  const colors = {
    background: isDarkMode ? 'bg-[#1e293b]' : 'bg-[#f8fafc]', // Dark slate or very light blue
    textPrimary: isDarkMode ? 'text-[#e2e8f0]' : 'text-[#2d3748]', // Lighter text for dark, slate gray for light
    textSecondary: isDarkMode ? 'text-[#94a3b8]' : 'text-[#718096]', // Muted blue-gray for secondary text
    accentBlue: isDarkMode ? 'text-[#60a5fa]' : 'text-[#3b82f6]', // Accent blue
    accentGreen: isDarkMode ? 'text-[#4ade80]' : 'text-[#22c55e]', // Accent green
    accentYellow: isDarkMode ? 'text-[#facc15]' : 'text-[#eab308]', // Accent yellow
    accentRed: isDarkMode ? 'text-[#f87171]' : 'text-[#dc2626]', // Accent red
    cardBackground: isDarkMode ? 'bg-[#2c3e50]' : 'bg-[#ffffff]', // Darker slate for dark mode, white for light mode
  };
  

  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const closeMenuOnOutsideClick = (e) => {
    if (isMenuOpen && !e.target.closest('.menu-button')) setIsMenuOpen(false);
  };

  const closeMenuOnScroll = () => {
    if (isMenuOpen) setIsMenuOpen(false);
  };
  

  useEffect(() => {
    // Close menu if clicking outside when menu is open
    const closeMenuOnOutsideClick = (e) => {
      if (isMenuOpen && !e.target.closest('.menu-button') && !e.target.closest('.mobile-menu')) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('click', closeMenuOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeMenuOnOutsideClick);
    };
  }, [isMenuOpen]);

  return (
    <Router>
      <ScrollToTop />
      <div className={`${colors.background} ${colors.textPrimary} min-h-screen`}>
        {/* Header Section */}
        <header className={`sticky top-0 ${colors.background} py-4 z-50 shadow-md`}>
      <div className="max-w-6xl mx-auto px-4 flex items-center justify-between">
        
        {/* Logo */}
        <Link 
              to="/" 
              className="text-2xl font-semibold tracking-tight hover:opacity-90 transition-opacity" 
              onClick={() => {
                scrollToTop();
                setIsMenuOpen(false);
              }}
            >
              SmileCare Analytics
            </Link>

        {/* Desktop Navigation Links */}
        <nav className="hidden md:flex items-center space-x-6 ml-auto">
          <Link to="/about" className={`${colors.textPrimary} hover:text-[#3b82f6] transition-colors`}>About</Link>
          <Link to="/careers" className={`${colors.textPrimary} hover:text-[#3b82f6] transition-colors`}>Careers</Link>
          <Link to="/support" className={`${colors.textPrimary} hover:text-[#3b82f6] transition-colors`}>Support</Link>
        </nav>

        {/* Theme Toggle Button (Visible on all screens) */}
        <button
          onClick={toggleTheme}
          className={`ml-4 p-2 rounded-full transition-all ${colors.background} hover:bg-opacity-20 shadow-md`}
          aria-label="Toggle Theme"
        >
          {isDarkMode ? (
            <Sun className="h-6 w-6 text-yellow-400 transition-colors" />
          ) : (
            <Moon className="h-6 w-6 text-blue-400 transition-colors" />
          )}
        </button>

{/* Hamburger Menu Button (visible on smaller screens) */}
<div className="md:hidden flex items-center">
  <button
    onClick={(e) => {
      e.stopPropagation();
      setIsMenuOpen(!isMenuOpen); // Toggle menu open state
    }}
    className="p-4 rounded focus:outline-none" // Increased padding for larger click area
    aria-label="Toggle Menu"
  >
    {isMenuOpen ? (
      <X className={`h-8 w-8 ${colors.textPrimary} transition-transform`} /> // Increased icon size
    ) : (
      <Menu className={`h-8 w-8 ${colors.textPrimary} transition-transform`} /> // Increased icon size
    )}
  </button>


        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className={`${colors.background} md:hidden flex flex-col items-center py-4 space-y-2 text-lg`}>

          <Link to="/about" className={`${colors.textPrimary} hover:text-[#3b82f6] transition-colors`} onClick={() => setIsMenuOpen(false)}>About</Link>
          <Link to="/careers" className={`${colors.textPrimary} hover:text-[#3b82f6] transition-colors`} onClick={() => setIsMenuOpen(false)}>Careers</Link>
          <Link to="/support" className={`${colors.textPrimary} hover:text-[#3b82f6] transition-colors`} onClick={() => setIsMenuOpen(false)}>Support</Link>
        {/* Theme Toggle Button (Visible on all screens) */}
        {/* <button
          onClick={toggleTheme}
          className={`ml-4 p-2 rounded-full transition-all ${colors.background} hover:bg-opacity-20 shadow-md`}
          aria-label="Toggle Theme"
        >
          {isDarkMode ? (
            <Sun className="h-6 w-6 text-yellow-400 transition-colors" />
          ) : (
            <Moon className="h-6 w-6 text-blue-400 transition-colors" />
          )}
        </button> */}
        </div>
      )}
    </header>

        {/* Routes Setup */}
        <Routes>
          <Route path="/" element={<MainContent isDarkMode={isDarkMode} colors={colors} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy isDarkMode={isDarkMode} />} />
          <Route path="/terms-of-service" element={<TermsOfService isDarkMode={isDarkMode} />} />
          <Route path="/support" element={<Support isDarkMode={isDarkMode} />} />
          <Route path="/careers" element={<Careers isDarkMode={isDarkMode} />} />
          <Route path="/about" element={<About isDarkMode={isDarkMode} />} />
        </Routes>

        {/* Footer Section */}
        <footer className={`py-6 px-4 md:px-8 ${isDarkMode ? 'bg-slate-800 text-gray-400' : 'bg-gray-100 text-gray-600'}`}>
          <div className="max-w-6xl mx-auto text-center text-sm">
            © {new Date().getFullYear()} SmileCare Analytics. All rights reserved.
          </div>
        </footer>
      </div>
    </Router>
  );
};

const MainContent = ({ isDarkMode, colors }) => {
  return (
    <div className="flex flex-col items-center">
      {/* Hero Section */}
      <section className="flex-grow px-10 py-12 w-full text-center">
        
        <div className="max-w-4xl mx-auto space-y-12">
          <div className={`${colors.background} mb-2 text-center`}>
          {/* <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md flex flex-col`}> */}
            <h1 className="text-3xl font-extrabold mb-4">Data-Driven Insights for Pediatric Dental Practices</h1>
            <p className="text-base max-w-lg mx-auto">
              We simplify your practice management by integrating Dentrix, Open Dental, Patterson, Eaglesoft, ADP, and more. Our intuitive dashboard provides real-time analytics to help your practice thrive.
            </p>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="mb-10 w-full px-6">
        <h2 className="text-2xl font-semibold mb-4 text-center">About SmileCare Analytics</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-4xl mx-auto">
          <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md flex flex-col`}>
            <p className="font-semibold text-lg mb-2">Effortless Integration</p>
            <p className={`text-sm ${colors.textSecondary}`}>
              We connect your essential platforms—Dentrix, Open Dental, Patterson, Eaglesoft, and ADP—into a cohesive system that's specifically tailored for pediatric practices.
            </p>
          </div>
          <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md flex flex-col`}>
            <p className="font-semibold text-lg mb-2">Actionable Pediatric Analytics</p>
            <p className={`text-sm ${colors.textSecondary}`}>
              Monitor the metrics that matter most in pediatric dentistry, from preventive care completion rates to treatment plan acceptance rates.
            </p>
          </div>
        </div>
      </section>

      {/* Demo Dashboard Section */}
      <section className="mb-10 w-full px-6">
        <h2 className="text-2xl font-semibold mb-6 text-center">Pediatric KPIs for Growth</h2>
        <div className="max-w-4xl mx-auto">
          <CompactDashboard isDarkMode={isDarkMode} />
        </div>
      </section>

      {/* Services Section */}
      <section className="mb-10 w-full px-6">
        <h2 className="text-2xl font-semibold mb-6 text-center">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
          <div className={`${colors.cardBackground} rounded-lg p-6 shadow-md transition-shadow hover:shadow-lg`}>
            <h3 className="text-xl font-bold mb-2 flex items-center">
              <Database className={`${colors.accentBlue} mr-2`} /> Initial Data Integration
            </h3>
            <p className={`text-sm mb-2 ${colors.textSecondary}`}>Setup + Monthly Subscription</p>
            <p className="text-sm mb-3">
              We work with your practice to configure data connections from platforms like Dentrix, Open Dental, and Patterson.
            </p>
            <p className={`text-sm font-semibold ${colors.accentBlue}`}>
              Pilot Pricing: $2,000 Setup + $1,500/month
            </p>
          </div>

          <div className={`${colors.cardBackground} rounded-lg p-6 shadow-md transition-shadow hover:shadow-lg`}>
            <h3 className="text-xl font-bold mb-2 flex items-center">
              <BarChart2 className={`${colors.accentBlue} mr-2`} /> Custom Analytics Dashboards
            </h3>
            <p className={`text-sm mb-2 ${colors.textSecondary}`}>Premium Analytics Solution</p>
            <p className="text-sm mb-3">
              Gain actionable insights with dashboards tailored to your practice's needs, from preventive care metrics to treatment acceptance.
            </p>
            <p className={`text-sm font-semibold ${colors.accentBlue}`}>
              Premium Plan: $3,500/month
            </p>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="mb-12 text-center px-6">
        <h2 className="text-2xl font-semibold mb-4">Ready to Transform Your Practice?</h2>
        <p className="text-sm max-w-md mx-auto">
          Contact us today to discover how SmileCare Analytics can empower your practice with data-driven insights. Reach out at <strong>info@smilecareanalytics.com</strong>
        </p>
      </section>
    </div>
  );
};

export default PediatricDashboardLandingPage;