import React from 'react';
import { Briefcase } from 'lucide-react';

const Careers = ({ isDarkMode }) => {
  // Define modern colors
  const colors = {
    background: isDarkMode ? 'bg-[#1e293b]' : 'bg-[#f8fafc]', // Dark slate or very light blue
    textPrimary: isDarkMode ? 'text-[#e2e8f0]' : 'text-[#2d3748]', // Lighter text for dark, slate gray for light
    textSecondary: isDarkMode ? 'text-[#94a3b8]' : 'text-[#718096]', // Muted blue-gray for secondary text
    accentBlue: isDarkMode ? 'text-[#60a5fa]' : 'text-[#3b82f6]', // Accent blue
    accentGreen: isDarkMode ? 'text-[#4ade80]' : 'text-[#22c55e]', // Accent green
    accentYellow: isDarkMode ? 'text-[#facc15]' : 'text-[#eab308]', // Accent yellow
    accentRed: isDarkMode ? 'text-[#f87171]' : 'text-[#dc2626]', // Accent red
    cardBackground: isDarkMode ? 'bg-[#2c3e50]' : 'bg-[#ffffff]', // Darker slate for dark mode, white for light mode
  };

  return (
    <div className={`min-h-screen ${colors.background} ${colors.textPrimary} flex flex-col items-center`}>
      <main className="flex-grow px-6 py-10 max-w-4xl w-full space-y-12">

        {/* Join Our Team Section */}
        {/* <section className="mb-10 text-center">
          <h1 className="text-3xl font-extrabold mb-4">Join Our Team</h1>
          <p className="text-base max-w-auto">
            At SmileCare Analytics, we’re a team of innovators dedicated to transforming pediatric dental practices through data-driven insights. Join us and make a real impact on healthcare technology.
          </p>
        </section> */}

        <section className="mb-10 text-center">
          <h1 className="text-3xl font-extrabold mb-4">Join Our Team</h1>
          <p className="text-base max-w-lg mx-auto">
            At SmileCare Analytics, we’re a team of innovators dedicated to transforming pediatric dental practices through data-driven insights. Join us and make a real impact on healthcare technology.
          </p>
        </section>

        {/* Why Work With Us */}
        <section className="mb-10">
        {/* <h2 className="text-2xl font-semibold mb-6 text-center">Current Openings</h2> */}
        <h2 className="text-2xl font-semibold mb-6 text-center">Why Work With Us?</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md flex flex-col`}>
              <p className="font-semibold text-lg mb-2">Flexible Hours & Remote Work</p>
              <p className={`text-sm ${colors.textSecondary}`}>Work in a way that supports your lifestyle. Balance life and career with our flexible schedules.</p>
            </div>
            <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md flex flex-col`}>
              <p className="font-semibold text-lg mb-2">Impactful, Meaningful Projects</p>
              <p className={`text-sm ${colors.textSecondary}`}>Work on initiatives that improve pediatric dental care and positively impact practices.</p>
            </div>
            <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md flex flex-col`}>
              <p className="font-semibold text-lg mb-2">Modern Tech & Tools</p>
              <p className={`text-sm ${colors.textSecondary}`}>Use the latest technology to solve problems creatively and stay at the cutting edge of healthcare analytics.</p>
            </div>
            <div className={`${colors.cardBackground} rounded-lg p-5 shadow-md flex flex-col`}>
              <p className="font-semibold text-lg mb-2">Collaborative Team Culture</p>
              <p className={`text-sm ${colors.textSecondary}`}>Join a supportive team that values each member’s contributions and celebrates success together.</p>
            </div>
          </div>
        </section>

        {/* Current Openings */}
<section className="mb-10">
  <h2 className="text-2xl font-semibold mb-6 text-center">Current Openings</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

    {/* Data Engineer Position */}
    <div className={`${colors.cardBackground} rounded-lg p-6 shadow-md transition-shadow hover:shadow-lg`}>
      <h3 className="text-xl font-bold mb-2 flex items-center">
        <Briefcase className={`${colors.accentBlue} mr-2`} /> Data Engineer
      </h3>
      <p className={`text-sm mb-2 ${colors.textSecondary}`}>Location: Remote (US) | Type: Full-Time</p>
      <p className="text-sm mb-3">
        Develop and maintain data infrastructure, integrate APIs, and ensure data quality. We seek a skilled problem-solver with a deep understanding of data management and a passion for precision.
      </p>
      <h4 className="font-semibold mb-2">Key Skills:</h4>
      <ul className={`list-disc list-inside text-sm ${colors.textSecondary} space-y-1`}>
        <li>Python & SQL programming</li>
        <li>API integration and management</li>
        <li>Data modeling and ETL processes</li>
      </ul>
    </div>

    {/* Product Manager Position */}
    <div className={`${colors.cardBackground} rounded-lg p-6 shadow-md transition-shadow hover:shadow-lg`}>
      <h3 className="text-xl font-bold mb-2 flex items-center">
        <Briefcase className={`${colors.accentGreen} mr-2`} /> Product Manager
      </h3>
      <p className={`text-sm mb-2 ${colors.textSecondary}`}>Location: Remote (US) | Type: Full-Time</p>
      <p className="text-sm mb-3">
        Lead product strategy and oversee cross-functional development teams. We’re looking for a strategic visionary who excels at fostering collaboration and driving results.
      </p>
      <h4 className="font-semibold mb-2">Key Skills:</h4>
      <ul className={`list-disc list-inside text-sm ${colors.textSecondary} space-y-1`}>
        <li>Strategic product roadmap planning</li>
        <li>Experience with agile methodologies and project management tools</li>
        <li>Strong analytical skills and user research expertise</li>
      </ul>
    </div>

  </div>
</section>


        {/* How to Apply */}
        <section className="mb-12 text-center">
          <h2 className="text-2xl font-semibold mb-4">How to Apply</h2>
          <p className="text-sm max-w-md mx-auto">
            Ready to join us? Send your resume and cover letter to <strong>careers@smilecareanalytics.com</strong>. We look forward to learning about you!
          </p>
        </section>
      </main>

      {/* Footer Section */}
      {/* <footer className={`py-4 w-full text-center ${colors.footerBackground}`}>
        <div className="text-xs">
          © {new Date().getFullYear()} SmileCare Analytics. All rights reserved.
        </div>
      </footer> */}
    </div>
  );
};

export default Careers;
