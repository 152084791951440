import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsOfService = ({ isDarkMode }) => {
  const navigate = useNavigate();

  return (
    <div className={`min-h-screen ${isDarkMode ? 'bg-slate-900 text-white' : 'bg-white text-gray-900'} flex flex-col`}>
      {/* Header Section */}
      <header className={`py-6 px-4 md:px-8 ${isDarkMode ? 'bg-slate-800' : 'bg-gray-100'} shadow-md`}>
        <div className="max-w-6xl mx-auto flex items-center justify-between">
          <button
            onClick={() => navigate('/')}
            className="text-sm font-medium text-blue-600 hover:text-blue-700 transition-colors"
          >
            ← Home
          </button>
          <h1 className="text-lg font-semibold">
            Terms of Service
          </h1>
          <span className="text-xs text-gray-500">Last Updated: 2024-11-05</span>
        </div>
      </header>

      {/* Content Section */}
      <main className="flex-grow px-6 py-8 max-w-4xl mx-auto">
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">1. Acceptance of Terms</h2>
          <p className="text-base">
            By using SmileCare Analytics, you agree to these Terms of Service. If you do not agree, please do not use our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">2. Service Description</h2>
          <p className="text-base">
            SmileCare Analytics provides data integration and analytics services for pediatric dental practices. This involves setting up connections to platforms like Dentrix, Open Dental, and Patterson, which may require API keys and access to your systems.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">3. User Responsibilities</h2>
          <p className="text-base">
            Access: You agree to provide necessary access and permissions for us to perform data integrations, including API keys and credentials.
          </p>
          <p className="text-base mt-2">
            Data Accuracy: You are responsible for ensuring that the data provided is accurate and up to date.
          </p>
          <p className="text-base mt-2">
            Security: You are responsible for maintaining the security of your systems and any credentials shared with us.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">4. Limitations of Liability</h2>
          <p className="text-base">
            SmileCare Analytics is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our services, even if advised of the possibility of such damages.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">5. Indemnification</h2>
          <p className="text-base">
            You agree to indemnify and hold SmileCare Analytics harmless from any claims, damages, or expenses arising from your use of our services or any breach of these terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">6. Modifications</h2>
          <p className="text-base">
            We may update these Terms of Service from time to time. We will notify you of any significant changes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-3">7. Termination</h2>
          <p className="text-base">
            We reserve the right to terminate or suspend access to our services at our discretion.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
          <p className="text-base">
            If you have any questions about these Terms of Service, please contact us at: <strong>[legal@smilecareanalytics.com]</strong>
          </p>
        </section>
      </main>

      {/* Footer Section */}
      <footer className={`py-4 px-4 md:px-8 ${isDarkMode ? 'bg-slate-800 text-gray-400' : 'bg-gray-100 text-gray-600'}`}>
        <div className="max-w-6xl mx-auto text-center text-sm">
          © {new Date().getFullYear()} SmileCare Analytics. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default TermsOfService;
